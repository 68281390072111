<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk10Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk10',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-大唐集团'
    getBulk10Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '竞价通告', value: res[0].bidAnnouncement},
        {text: '竞价单编号', value: res[0].biddingDocumentNumber},
        {text: '承运业务类型', value: res[0].businessType},
        {text: '运输方式', value: res[0].transportationMethod},
        {text: '币种', value: res[0].currency},
        {text: '承/倒运数量', value: res[0].carriedOrreversedNumber},
        {text: '发货地', value: res[0].shipmentPlace},
        {text: '到货地', value: res[0].deliveranceDestination},
        {text: '报价单位', value: res[0].quotationUnit},
        {text: '交货日期', value: res[0].deliveryDate},
        {text: '运距', value: res[0].transportationDistance},
        {text: '第一次报价截止时间', value: res[0].firstQuotationDeadline},
        {text: 'projectId', value: res[0].projectId},
        {text: '船期', value: res[0].shippingSchedule},
        {text: '受载期', value: res[0].loadPeriod},
        {text: '滞期费', value: res[0].delayFee},
        {text: '装率（吨/天）', value: res[0].loadingRate},
        {text: '卸率（吨/天）', value: res[0].unloadingRate},
        {text: '卸港条件（满载吃水（米））', value: res[0].unloadingConditions},
        {text: '是否含税', value: res[0].includeTax},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
